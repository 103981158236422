import React from "react"
import Helmet from "react-helmet";
// import "./main.css";
import "./flicker.css";

export default function Preview() {
  return (
    <div>
      <Helmet title="DVMA" />
    <div class="sign">
      <span class="fast-flicker">D</span>V<span class="flicker">M</span>A
    </div>
    <div id="container">
    <iframe width="30%"
        height="500"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1116418267%3Fsecret_token%3Ds-zJVU8ij42dJ&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false">
          </iframe>
      </div>
    </div>
  )
}
